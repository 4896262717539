<template>
  <div class="noticeBox">
    <div class="noticeHeader">
      <img :src="labaIcon" alt="">
      考试公告
    </div>

    <div class="noticeContent">
      <ul>
        <template v-for="(item,index) in list">
          <li @click="noticeDetails(item.id)">
            <span class="noticeTitle" :title="item.title">{{item.title}}</span>
            <span class="spanTime">{{item.createTime}}</span>
          </li>
        </template>
      </ul>
      <div class="pagination">
        <el-pagination
          background
          @current-change="currentChange"
          layout="prev, pager, next"
          :page-size="10"
          :current-page="page"
          :total="total">
        </el-pagination>
      </div>
    </div>

    <noticeDetails :id="id" v-if="dialogVisible" v-model="dialogVisible" />
  </div>
</template>
<script>
import labaIcon from '@/assets/laba-icon.png';
import noticeDetails from '@/views/notice/noticeDetails.vue';
import {listNoticeByPage} from '@/api/noticeInformation/noticeInformation';
export default {
  components:{
    noticeDetails
  },
  data() {
    return {
      labaIcon,
      total:0,
      page:1,
      dialogVisible:false,
      list:[],
      id:''
    }
  },
  mounted() {
    this.getData()
  },
  methods:{
    getData(){
      let data = {
        page:this.page,
        limit:10,
        state:0,
        ownToken:this.$ownToken
      }

      listNoticeByPage(data).then(res=>{
        if(res.status == 200){
          this.list = res.data.rows;
          this.list.forEach(notice => {             
              const position = this.list.indexOf(notice)
              // 修改list中的数据
              notice.createTime = notice.createTime.substr(0,10);
              this.$set(this.list, position, notice)          
          })
          this.total = res.data.total;
        }
      })
    },
    // 详情
    noticeDetails(id){
      this.id = id;
      this.dialogVisible = true;
    },
    // 分页
    currentChange(val){
      this.page = val;
      this.getData()
    }
  }
}
</script>
<style lang="scss" scoped>
  .noticeBox{
    .noticeHeader{
      color: #BD192C;
      font-size: 16px;
      border: 1px solid #EEEDE9;
      padding: 10px 20px;
      font-weight: bold;
      display: flex;
      align-items: center;
      img{
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
    }
    .noticeContent{
      padding: 0 25px;
      ul{
        li{
          display: flex;
          justify-content: space-between;
          height: 50px;
          line-height: 50px;
          border-bottom: 1px solid #EEEDE9;
          font-size: 14px;
          cursor: pointer;

          .noticeTitle{
            display: block;
            max-width: 600px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
            // line-height: 24px;
          }

          .spanTime{
            color: rgb(170, 170, 170);
            display: block;
            min-width: 160px;
          }
        }
      }
    }
  }
</style>