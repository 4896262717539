<template>
  <div>
    <el-dialog :visible.sync="dialogVisible" top="80px" custom-class="noticeDetailsBox" width="80%" :show-close="false"
    :close-on-click-modal="false" :close-on-press-escape="false">
      <div class="noticeHeader">
        <div class="noticeTitle">{{contentObj.title}}</div>
        <div class="noticeTime">发布日期：{{contentObj.createTime}}</div>
      </div>
      <div class="editorBox" v-html="contentObj.content">
        　
      </div>
      <div class="closeBox">
        <div class="fileBox">
          <ul>
            <li v-for="(item,index) in fileList" :key="index" @click="downupFun(item.path)">{{item.name}}</li>
          </ul>
        </div>
        <el-button type="danger" class="closeBtn" @click="handleClose">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {getNoticeById} from '@/api/noticeInformation/noticeInformation'
export default {
  model:{
    prop:'dialogVisible',
    event:'change'
  },
  props:['dialogVisible','id'],
  data() {
    return {
      fileList:[],
      contentObj:{}
    }
  },
  mounted() {
    this.getDetails()
  },
  methods:{
    getDetails(){
      let data = {
        id:this.id,
        ownToken:this.$ownToken
      }
      getNoticeById(data).then(res=>{
        if(res.status == 200){
          this.contentObj = res.data;
          this.contentObj.createTime = this.contentObj.createTime.substr(0,10);
          this.fileList = res.data.adjunctList;
        }
      })
    },
    // 下载
    downupFun(path){
      window.location.href = path
    },
    // 关闭弹框
    handleClose(){
      this.$emit('change',false)
    }
  }
}
</script>
<style lang="scss" scoped>
  .noticeHeader{
    border-bottom: 1px solid #DADADA;
    padding-bottom: 25px;
    .noticeTitle{
      text-align: center;
      font-size: 18px;
      font-weight: bold;
    }
    .noticeTime{
      font-size: 14px;
      color: #9F9F9F;
      text-align: center;
      margin-top: 20px;
    }
  }
  .editorBox{
    margin-top: 20px;
    padding: 0 70px;
  }
  .closeBox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding: 10px 70px;
    border-top: 1px solid #DADADA;

    .fileBox{
      ul{
        li{
          cursor: pointer;
          &:hover{
            color: #BD192C;;
          }
        }
      }
    }

    .closeBtn{
      width: 100px;
      height: 40px;
      background: rgb(189, 25, 44);
      border: none;
    }
  }
</style>