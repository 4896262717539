import axios from '@/utils/axios'

// 新增公告
export function insertNotice(data){
  return axios({
    url:'/api/pc/backstage/notice/insertNotice',
    method:'put',
    data
  })
}

// 修改公告
export function updateNotice(data){
  return axios({
    url:'/api/pc/backstage/notice/updateNotice',
    method:'post',
    data
  })
}
// 删除公告
export function deleteNotice(params){
  return axios({
    url:'/api/pc/backstage/notice/deleteNotice',
    method:'get',
    params
  })
}

// 撤销公告
export function cxNotice(params){
  return axios({
    url:'/api/pc/backstage/notice/cxNotice',
    method:'get',
    params
  })
}


// 删除公告附件
export function deleteNoticeFileById(params){
  return axios({
    url:'/api/pc/backstage/notice/deleteNoticeFileById',
    method:'get',
    params
  })
}



// 获取公告
export function listNoticeByPage(params){
  return axios({
    url:'/api/pc/backstage/notice/listNoticeByPage',
    method:'get',
    params
  })
}

// 获取公告详情
export function getNoticeById(params){
  return axios({
    url:'/api/pc/backstage/notice/getNoticeById',
    method:'get',
    params
  })
}